<template>
  <section class="upload-videooz">
    <div class="container">
      <h2>
        {{ $t("newProductTitle") }}
      </h2>
      <h5 class="text-muted mt-2 mb-4">
        {{ $t("newProductDescription") }}
      </h5>
      <div>
        <div class="row justify-content-center">
          <div
            v-for="(item, i) in Types"
            :key="i"
            class="col-lg-3 col-md-6 my-3"
          >
            <span
              v-if="!returnModule(item.packageModule)"
              class="module-locked"
            >
              <i
                style="font-size: 20px"
                class="icofont-ui-lock text-warning"
              ></i>
              {{ $t("moduleLocked") }}
            </span>

            <div class="video-file">
              <img :src="item.icon" width="80" :alt="item.title" />
              <h3>{{ item.title }}</h3>
              <span> {{ $t("addToSection") }} </span>
              <form class="btn-sbmit">
                <button
                  v-if="returnModule(item.packageModule)"
                  @click="$emit('click:type', item)"
                  for="file-upload"
                  class="custom-file-upload"
                >
                  {{ $t("getStarted") }}
                </button>
                <button
                  v-else
                  @click="$emit('click:type', item)"
                  for="file-upload"
                  class="custom-file-upload btn-locked"
                >
                  <i style="font-size: 13px" class="icofont-ui-lock"></i>
                  {{ $t("moduleLocked") }}
                </button>
              </form>
            </div>
            <!--video-file end-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--upload-videooz end-->
</template>

<script>
import Modules from "../../mixins/Modules.js";
import Types from "../Type/Types.js";
export default {
  mixins: [Types, Modules],
};
</script>
